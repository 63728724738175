import classnames from 'classnames';
import {FC, ReactNode, ButtonHTMLAttributes} from 'react';

import {CommonStyledProps} from 'shared/config/types';
import styles from './index.module.scss';


type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & 
  CommonStyledProps & {
    onClick?: (e?: MouseEvent | Event) => void;
    children?: ReactNode;
    squared?: boolean;
  };

export const Button: FC<ButtonProps> = ({
  type,
  children,
  styled = '',
  onClick,
  squared,
  ...rest
}) => {
  return (
    <button type={type}
            className={classnames(
              styles.button, 
              squared && styles.button__squared,
              styled,
            )}
            onClick={onClick}
            {...rest}>
      {children}
    </button>
  );
};

Button.defaultProps = {
  onClick: () => null,
  type: 'submit',
  children: null,
  squared: false,
};
