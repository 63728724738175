import classnames from 'classnames';
import {FC} from 'react';

import {CommonStyledProps} from 'shared/config/types';
import styles from '../index.module.scss';


type ForbiddenBadgeProps = CommonStyledProps & {
  withEffect?: boolean;
};

export const ForbiddenBadge: FC<ForbiddenBadgeProps> = ({styled, withEffect}) => {
  return (
    <span
      className={classnames(
        styles.base,
        styles.forbidden,
        withEffect && styles.effect,
        Boolean(styled) && styled,
      )}
    />
  );
};

ForbiddenBadge.defaultProps = {
  withEffect: false,
};
