import {Link} from 'react-router-dom';
import {v4 as uuid} from 'uuid';

import styles from '../index.module.scss';


const navigationRoutes = [{url: '/', name: 'Главная'}];

export const FooterNavigation = () => {
  return (
    <nav className={styles.navigation}>
      <ul>
        {navigationRoutes.map((route) => (
          <Link key={uuid()} to={route.url}>
            {route.name}
          </Link>
        ))}
      </ul>
    </nav>
  );
};
