import {QueryClient, QueryClientProvider} from 'react-query';
import {Location, NavigateFunction, useLocation, useNavigate} from 'react-router-dom';
import {RootRouter} from 'pages';

import './index.scss';


type BrowserHistory = {
  navigate: NavigateFunction | null;
  location: Location | null;
};

export const history: BrowserHistory = {
  navigate: null,
  location: null,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  history.navigate = useNavigate();
  history.location = useLocation();

  return (
    <QueryClientProvider client={queryClient}>
      <RootRouter />
    </QueryClientProvider>
  );
};

export default App;
