import classnames from 'classnames';
import {useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';

import {useGuild} from 'shared/lib/store/slices/guild_slice';
import {Button, Dialog, DialogMethods} from 'shared/ui';
import {Tooltip} from 'shared/ui/tooltip';
import {useSubscribeGuildMutation} from './hooks/subscribe_guild_mutation';
import styles from './index.module.scss';


export const UserSelectGuild = () => {
  const {guilds, setUserGuild} = useGuild();
  const dialog = useRef<DialogMethods | null>(null);
  const navigate = useNavigate();

  const [selectedGuild, setSelectedGuild] = useState<number | null>(null);

  const subscribe = useSubscribeGuildMutation();

  const onCancel = () => {
    navigate('/course/1');
  };

  const selectGuild = (id: number) => {
    setSelectedGuild(id);
  };

  const handleGuildSelection = () => {
    if (selectedGuild) {
      subscribe.mutate(selectedGuild);
      setUserGuild(selectedGuild);
      dialog.current?.close();
    }
  };

  return (
    <Dialog ref={dialog}
            onLoad={true}
            onCancel={onCancel}>
      <div className={styles.container}>
        <div className={styles.info}>
          <span className={styles.info__heading}>
            Добро пожаловать!
          </span>
          <span className={styles.info__message}>
            Выберите фракцию для вступления.
          </span>
          <span className={styles.info__message}>
            Фракция будет с Вами весь путь – отнеситесь к её выбору внимательно.
          </span>
        </div>
        <div className={styles.choices}>
          {guilds.length === 0 ? <div className={styles.fallback}>
            По неизвестной причине, выбор фракции недоступен.<br/>Навестите нас позже
          </div> : guilds.map((guild, index) => (
            <Button className={classnames(styles.guild_select, 
              selectedGuild === guild.id && styles[`guild_selected_${index}`])}
                    key={uuidv4()}
                    onClick={() => selectGuild(guild.id)}>
              <span className={styles.guild_select__name}>
                {guild.tagName}
              </span>
              <div className={styles.guild_select__divider}/>
              <span className={styles.guild_select__users}>
                {guild.users.length}
                {/* eslint-disable-next-line max-len */}
                <Tooltip message={'Если состав фракций будет отличаться по количеству слишком сильно, то вступить в гильдию с большим количеством участников временно будет нельзя.'}/>
              </span>
              <span className={styles.guild_select__leader}>
                Лидер: <strong>{guild?.leader?.login || 'Отсутствует'}</strong>
              </span>
            </Button>))}
        </div>
        <Button squared
                styled={styles.select_guild}
                disabled={guilds.length === 0}
                onClick={handleGuildSelection}>
          Выбрать фракцию
        </Button>
      </div>
    </Dialog>
  );
};
