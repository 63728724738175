import {FC, ReactNode} from 'react';

import {BasicHelmet, ExtendedHelmet} from './variations';


type HelmetExtensions = {
  Basic: typeof BasicHelmet;
  Extended: typeof ExtendedHelmet;
};

type HelmetProps = {
  children: ReactNode;
};

export const Helmet: FC<HelmetProps> & HelmetExtensions = ({children}) => {
  return <>{children}</>;
};

Helmet.Basic = BasicHelmet;
Helmet.Extended = ExtendedHelmet;
