import {CourseMaterialPage} from 'pages/course_material';


export const CourseMaterialRoute = {
  path: ':courseId',
  name: 'courseId',
  children: [
    {
      path: 'material',
      name: 'Material',
      children: [
        {
          path: ':materialId',
          name: 'materialId',
          element: <CourseMaterialPage />,
        },
      ],
    },
  ],
};
