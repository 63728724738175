import {useQuery} from 'react-query';
import {useNavigate} from 'react-router-dom';

import {apiService} from 'shared/api';
import {Guild} from 'shared/config/types';
import {sessionService} from 'shared/lib/session_service';
import {useGuild} from 'shared/lib/store/slices/guild_slice';


export const useGetGuilds = () => {
  const setGuilds = useGuild((state) => state.setGuilds);
  const navigate = useNavigate();

  return useQuery<Guild[]>({
    queryKey: 'guilds',
    queryFn: () => apiService.getGuilds(),
    onSuccess(guilds: Guild[]) {
      setGuilds(guilds);
    },
    onError() {
      sessionService.revokeSession();
      navigate('#/login');
    }
  });
};
