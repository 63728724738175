import {Link} from 'react-router-dom';

import {apiService} from 'shared/api';
import {SignInParams} from 'shared/config/types';
import {Error, Form, Input, Template} from 'shared/ui';
import {useForm} from './hooks/use_form';
import {useRedirectMutation} from './hooks/use_redirect_mutation';
import styles from './index.module.scss';


export const LoginForm = () => {
  const {disabled, error, register, handleSubmit} =
      useForm<SignInParams>(useRedirectMutation(apiService.signIn, '/'));

  return (
    <Template.FlexWrapper styled={styles.base}>
      <Form title="Вход"
            disabled={disabled}
            styled={styles.form}
            onSubmit={handleSubmit()}>
        <h3>Вход</h3>
        <Input id="username"
               type="text"
               label="Логин"
               styled={styles.form_input}
               refs={register('login', {required: true})}/>
        <Input id="password"
               type="password"
               label="Пароль"
               styled={styles.form_input}
               refs={register('password', {required: true})}/>
        {error && <Error message={error} />}
      </Form>
      <Link to="/register">Регистрация</Link>
    </Template.FlexWrapper>
  );
};
