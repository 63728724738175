import classnames from 'classnames';
import {ChangeEvent, useRef, useState} from 'react';


import {apiService} from 'shared/api';
import {sessionService} from 'shared/lib/session_service';
import {useGuild} from 'shared/lib/store/slices/guild_slice';
import {Button} from 'shared/ui';
import styles from './index.module.scss';


export const UserEditGuildMessage = () => {
  const [editing, setEditing] = useState(false);
  const guild = useGuild((state) => state.userGuild);

  const [prevDescription, setPrevDescription] = useState('');
  // eslint-disable-next-line no-extra-boolean-cast
  const [description, setDescription] = useState(Boolean(guild?.description) 
    ? guild?.description
    : 'Добавьте описание гильдии');

  const textarea = useRef<HTMLTextAreaElement>(null);

  const handleOpenEditMode = async () => {
    const canEdit = await apiService.validateGuildLeader();
    if (canEdit) {
      setEditing(true);
      setTimeout(() => textarea.current?.focus(), 0);
      setPrevDescription(description || '');
    } else {
      sessionService.revokeSession();
    }
  };

  const handleDescriptionChange = (e: ChangeEvent) => {
    setDescription(() => (e.target as HTMLTextAreaElement).value);
  };

  const handleCancelEditMode = () => {
    setDescription(prevDescription);
    setPrevDescription('');
    setEditing(false);
  };

  const handleSubmitEdit = () => {
    if (!description || description.trim().length === 0) {
      setDescription(prevDescription);
    } else {
      apiService.setGuildMessage({description});
    }
    setPrevDescription('');
    setEditing(false);
  };

  if (!sessionService.validateGuildLeaderSession()) {
    return (
      <div className={styles.guild_message}>
        <span className={styles.guild_message__content}>
          {/* eslint-disable-next-line no-extra-boolean-cast */}
          {Boolean(guild?.description)
            ? guild?.description 
            : 'Лидер гильдии может добавить, или же изменить ее описание'}
        </span>
        <span className={styles.guild_message__author}>
          {guild?.leader 
            ? ''
            : 'Назначьте лидера, чтобы изменить описание гильдии'}
        </span>
      </div>
    );
  }

  return (
    editing ? (
      <div className={classnames(styles.guild_message, styles.leader)}>
        <textarea ref={textarea}
                  className={styles.textarea}
                  value={description}
                  onChange={handleDescriptionChange}/>
        <span className={styles.guild_message__author}>
          {guild?.leader?.login}
          <Button styled={classnames(styles.button, styles.save_button)}
                onClick={handleSubmitEdit}>
            <div className={styles.save_button_icon}/>
          </Button>
          <Button styled={classnames(styles.button, styles.cancel_button)}
                onClick={handleCancelEditMode}>
            <div className={styles.cancel_button_icon}/>
          </Button>
        </span>
      </div>
    ) : (
      <div className={classnames(styles.guild_message, styles.leader)}>
        <Button styled={classnames(styles.button, styles.edit_button)}
                onClick={handleOpenEditMode}>
          <div className={styles.edit_button_icon}/>
        </Button>
        <span className={styles.guild_message__content}>
          {description}
        </span>
      </div>
    )
  );
};
