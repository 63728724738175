import classnames from 'classnames';
import {FC, FormEventHandler, ReactNode, ComponentPropsWithRef} from 'react';

import {CommonStyledProps} from 'shared/config/types';
import {Button} from 'shared/ui';
import styles from './index.module.scss';


type FormProps = 
  ComponentPropsWithRef<'form'> & CommonStyledProps & {
    children: ReactNode[] | ReactNode;
    disabled: boolean;
    title: string;
    onSubmit: FormEventHandler<HTMLFormElement>;
  };

export const Form: FC<FormProps> = ({
  disabled,
  children,
  styled,
  title,
  onSubmit,
  ...rest
}) => {
  return (
    <form className={classnames(styles.form, styled && styled)}
          onSubmit={onSubmit}
          {...rest}>
      {children}
      <Button type="submit" 
              disabled={disabled} 
              styled={styles.submit}>
        <span>{disabled ? 'Загрузка' : title}</span>
      </Button>
    </form>
  );
};
