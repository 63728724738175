import {FC, ReactNode} from 'react';


type SectionContentProps = {
  content: ReactNode;
};

export const SectionContent: FC<SectionContentProps> = ({content}) => {
  return <div>{content}</div>;
};
