import {FC, ReactNode} from 'react';
import {MainLayout, SectionLayout} from './variations';


type LayoutExtensions = {
  Main: typeof MainLayout;
  Section: typeof SectionLayout;
};

type LayoutProps = {
  children: ReactNode;
};

export const Layout: FC<LayoutProps> & LayoutExtensions = ({children}) => {
  return <>{children}</>;
};

Layout.Main = MainLayout;
Layout.Section = SectionLayout;
