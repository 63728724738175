import axios, {AxiosError, AxiosRequestConfig} from 'axios';

import {PostAnswerParams, PostInfo, SignInResponse, SignInParams, ChapterChild, Course, PassedMaterialResponse, Guild, ChangeGuildMessage} from 'shared/config/types';
import {sessionService} from 'shared/lib/session_service';


export const API_URL = '';

export const config: AxiosRequestConfig = {
  withCredentials: true,
  headers: {
    Authorization: `Bearer ${sessionService.getSessionToken()}`,
  },
};

export const axiosClient = axios.create({baseURL: API_URL, ...config});

axiosClient.interceptors.request.use((req) => {
  req.headers.Authorization = `Bearer ${sessionService.getSessionToken()}`;
  return req;
});

const signIn = async (params: SignInParams): Promise<void> => {
  try {
    const {data} = await axiosClient.post<SignInResponse>(`/api/auth/signin`, params);
    sessionService.createSession(data.login, data.accessToken, data.roles);
  } catch (e) {
    throw new Error((e as AxiosError).message);
  }
};

const signUp = async (params: SignInParams): Promise<void> => {
  try {
    await axiosClient.post(`/api/auth/signup`, params);
  } catch (e) {
    throw new Error((e as AxiosError).message);
  }
};

const getCourse = async (id: string): Promise<Course> => {
  try {
    const {data: guild} = await axiosClient.get(`/student/ping/guildId`);
    sessionService.setSessionGuild(guild);
    const {data: course} = await axiosClient.get(`/course/${id}`);
    const {data: progress} = await axiosClient.get(`/course/week-progress/${id}`);
    return {...course, weekProgress: progress};
  } catch (e: any) {
    throw new Error((e as AxiosError).message);
  }
};

// eslint-disable-next-line max-len
const getCourseChapter = async (courseId: string, chapterId: string): Promise<ChapterChild[]> => {
  try {
    await axiosClient.get(`/student/ping`);
    const {data} = await axiosClient.get(`/course/${courseId}/chapter/${chapterId}`);
    return data;
  } catch (e: any) {
    throw new Error((e as AxiosError).message);
  }
};

const getCourseWeekProgress = async (id: string): Promise<any> => {
  try {
    const {data} = await axiosClient.get(`/course/week-progress/${id}`);
    return data;
  } catch (e: any) {
    throw new Error((e as AxiosError).message);
  }
};

// eslint-disable-next-line max-len
const answerQuestion = async (params: PostAnswerParams): Promise<PassedMaterialResponse> => {
  try {
    const {data} = await axiosClient.post(`/course/question`, params);
    return data;
  } catch (e: any) {
    throw new Error((e as AxiosError).message);
  }
};

const passInfo = async (params: PostInfo): Promise<PassedMaterialResponse> => {
  try {
    const {data} = await axiosClient.get(`/course/info/${params.info}`);
    return data;
  } catch (e: any) {
    throw new Error((e as AxiosError).message);
  }
};

const getGuilds = async (): Promise<Guild[]> => {
  try {
    await axiosClient.get(`/student/ping`);
    const {data: guild} = await axiosClient.get(`/student/ping/guildId`);
    sessionService.setSessionGuild(guild);
    const {data: guilds} = await axiosClient.get(`/guild?orderBy=name`);
    return guilds;
  } catch (e: any) {
    throw new Error((e as AxiosError).message);
  }
};

const getGuild = async (id: string): Promise<Guild> => {
  try {
    const {data} = await axiosClient.get(`/guild/${id}`);
    return data;
  } catch (e: any) {
    throw new Error((e as AxiosError).message);
  }
};

const getUserGuild = async (): Promise<string | null> => {
  try {
    const {data} = await axiosClient.get(`/student/ping/guildId`);
    sessionService.setSessionGuild(data);
    return data;
  } catch (e: any) {
    throw new Error((e as AxiosError).message);
  }
};

// eslint-disable-next-line max-len
const setGuildMessage = async (params: ChangeGuildMessage): Promise<string> => {
  try {
    const {data} = await axios.put(`/guild/leader/edit`, 
      params.description, {
        headers: { 
          Authorization: `Bearer ${sessionService.getSessionToken()}`,
          'Content-Type': 'text/plain' 
        }
      }
    );
    return data;
  } catch (e: any) {
    throw new Error((e as AxiosError).message);
  }
};

// eslint-disable-next-line max-len
const subscribeGuild = async (id: number): Promise<Guild> => {
  try {
    await axiosClient.put(`/guild/subscription/${id}`);
    const {data} = await axiosClient.get(`/guild/${id}`);
    return data;
  } catch (e: any) {
    throw new Error((e as AxiosError).message);
  }
};


// eslint-disable-next-line max-len
const validateGuildLeader = async (): Promise<boolean> => {
  try {
    const {data} = await axiosClient.get('/student/ping/leader');
    return data;
  } catch (e: any) {
    throw new Error((e as AxiosError).message);
  }
};

export const apiService = {
  signIn,
  signUp,
  getCourse,
  getCourseChapter,
  getCourseWeekProgress,
  answerQuestion,
  subscribeGuild,
  validateGuildLeader,
  passInfo,
  getGuilds,
  getGuild,
  getUserGuild,
  setGuildMessage,
};
