import {FC, ReactNode} from 'react';

import {MainHeading, SectionHeading, SecondaryHeading} from './specs';


type HeadingExtentions = {
  Main: typeof MainHeading;
  Section: typeof SectionHeading;
  Secondary: typeof SecondaryHeading;
};

type HeadingProps = {
  children: ReactNode;
};

export const Heading: FC<HeadingProps> & HeadingExtentions = ({children}) => {
  return <>{children}</>;
};

Heading.Main = MainHeading;
Heading.Section = SectionHeading;
Heading.Secondary = SecondaryHeading;
