import classnames from 'classnames';
import {memo} from 'react';
import {Link} from 'react-router-dom';

import {Template} from 'shared/ui';
import styles from './index.module.scss';
import {FooterNavigation} from './specs';


export const Footer = () => {
  return (
    <footer className={styles.base}>
      <Template.FlexWrapper styled={classnames('container', styles.wrapper)}>
        <Link to="/">
          <span className={classnames(styles.logo, styles.logo_cm)} />
        </Link>
        <span className={styles.divider} />
        <FooterNavigation />
        <span className={styles.divider} />
        <Link to="https://itmo.ru/ru/">
          <span className={classnames(styles.logo, styles.logo_itmo)} />
        </Link>
      </Template.FlexWrapper>
    </footer>
  );
};

export const MemoizedFooter = memo(Footer);
