import {GuildBanner, GuildInfo, GuildMembers, GuildWarBanner} from 'entities/guild';
import {useGetGuilds} from 'entities/guild/hooks/use_get_guilds';
import {UserSelectGuild} from 'features/guild';
import {UserEditGuildMessage} from 'features/guild/user_edit_guild_message';
import {sessionService} from 'shared/lib/session_service';
import {Spinner} from 'shared/ui';
import {Layout} from 'widgets/layout';
import styles from './index.module.scss';


export const GuildPage = () => {
  const {data, isLoading, isFetching} = useGetGuilds();

  if (!data || isLoading || isFetching) {
    return <Spinner.Page/>;
  }

  return (
    <Layout.Main>
      <div className={`container ${styles.base}`}>
        <GuildBanner/>
        <GuildWarBanner/>
        <GuildInfo>
          <UserEditGuildMessage/>
        </GuildInfo>
        <GuildMembers/>
      </div>
      {Boolean(!sessionService.getSessionGuild()) && <UserSelectGuild/>}
    </Layout.Main>
  );
};
