import classnames from 'classnames';
import {Link} from 'react-router-dom';

import {useCourse} from 'shared/lib/store';
import styles from './index.module.scss';


export const UserEnterGuild = () => {
  const progress = useCourse((course) => course.progress!);

  const notAvailable = Boolean((progress < 13));

  return (
    <div className={styles.base}>
      {notAvailable ? <div>
        Скоро будет<br/>доступно
      </div> : <div>
        Открыта новая<br/>функция!
      </div>}
      <Link to="/guild"
            className={classnames(styles.link, notAvailable && styles.link_disabled)}>
        <span className={styles.link_icon}/>
        <span>Выбор фракции</span>
      </Link>
    </div>
  );
};
