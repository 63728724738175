import {Link} from 'react-router-dom';

import {useCourseWeek} from 'shared/lib/store';
import styles from '../index.module.scss';


export const CourseMaterialHeader = () => {
  const title = useCourseWeek((state) => state.title);

  return (
    <div className={styles.aside_header}>
      <Link to="/course/2" className={styles.aside_header_breadcrumb} />
      {title && <span>{title}</span>}
    </div>
  );
};
