import {v4 as uuidv4} from 'uuid';

import {UserRole} from 'shared/config/types';
import {useGuild} from 'shared/lib/store/slices/guild_slice';
import {Tooltip} from 'shared/ui/tooltip';
import styles from '../index.module.scss';
import {GuildMemberCard} from './guild_member_card';


export const GuildMembers = () => {
  const guild = useGuild((state) => state.userGuild);

  return (
    <table className={styles.members}>
      <thead className={styles.members_attributes}>
        <tr>
          <th scope="col">Пользователь</th>
          <th scope="col">
            Очки опыта
            {/* eslint-disable-next-line max-len */}
            <Tooltip message={'Общий счет фракции складывается из очков опыта участников, набранных после вступления во фракцию.'}/>
          </th>
          <th scope="col">Рейтинг</th>
          <th scope="col">Статус</th>
          <th scope="col">
            Локация
            {/* eslint-disable-next-line max-len */}
            <Tooltip message={'Локация — этот параметр определяет, на каком этапе находится участник гильдии.'}/>
          </th>
        </tr>
      </thead>
      <tbody className={styles.members_list}>
        {guild?.users.sort((a, b) => b.experience - a.experience).map((user, index) => (
          <GuildMemberCard key={uuidv4()}
                           role={user.roles.includes(UserRole.ROLE_LEADER) 
                             ? 'Лидер' 
                             : 'Участник'}
                           username={user.login}
                           experience={user.experience}
                           rating={index + 1}
                           status={user.lastOnline}
                           location={user.location.name} />))}
      </tbody>
    </table>
  );
};
