import {FC} from 'react';

import {pluralize} from 'shared/lib/pluralize';
import styles from '../index.module.scss';


type GuildMemberCardProps = {
  role: string;
  username: string;
  experience: number;
  rating: number;
  status: number;
  location: string;
};

export const GuildMemberCard: FC<GuildMemberCardProps> = ({
  role,
  username,
  experience,
  rating,
  status,
  location,
}) => {

  const getStatus = (timestamp: number) => {
    const diff = Math.floor((Date.now() - timestamp) / 1000);

    if (diff < 5 * 60) {
      return 'В сети';
    } else if (diff >= 5 * 60 && diff <= 30 * 60) {
      return 'Недавно';
    } else if (diff > 30 * 60 && diff <= 60 * 60) {
      return 'Меньше часа назад';
    } else {
      const hours = Math.floor(diff / 3600);
      if (hours < 24) {
        return 'В течение дня';
      }
      if (hours >= 24) {
        return 'Не в сети';
      }
      return pluralize(hours, ['час назад', 'часа назад', 'часов назад']);
    }
  };

  return (
    <tr className={styles.member}>
      <td className={styles.member_user}>
        <div className={styles.member_user__info}>
          {role === 'Лидер' && (
            <span className={styles.member_user__role}>
              {role}
            </span>
          )}
          <span className={styles.member_user__username}>
            {username}
          </span>
        </div>
      </td>
      <td className={styles.member_experience}>{experience}</td>
      <td className={styles.member_rating}>{rating}</td>
      <td className={styles.member_status}>{getStatus(status)}</td>
      <td className={styles.member_location}>{location}</td>
    </tr>
  );
};
