import {RegisterForm} from 'features/form'; 
import {Template} from 'shared/ui';
import styles from './index.module.scss';


export const RegisterPage = () => {
  return (
    <Template.FlexCentered styled={styles.page}>
      <RegisterForm />
    </Template.FlexCentered>
  );
};
