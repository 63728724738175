import {create} from 'zustand';
import {persist, createJSONStorage} from 'zustand/middleware';

import {Course, Week} from 'shared/config/types';


type CourseSlice = {
  id: string | null;
  name: string | null;
  description: string | null;
  progress: number | null;
  weekProgress: any[];
  experience: number | null;
  chapters: Week[];
  activeChapter: Week | null;
  setCourse: (course: Course) => void;
  setWeekProgress: (weekProgress: number[]) => void;
  setActiveChapter: (id: string, next?: boolean) => void;
  setNextChapterActive: (id: string) => void;
};

export const useCourse = create<CourseSlice>()(
  persist(
    (set, get) => ({
      id: null,
      name: null,
      description: null,
      progress: null,
      weekProgress: [],
      experience: null,
      chapters: [],
      activeChapter: null,

      setCourse: (course) => {
        set({...course, activeChapter: course?.chapters[0]});
      },

      setWeekProgress: (weekProgress) => {
        set({weekProgress});
      },

      setActiveChapter: (id) => {
        const {chapters} = get();
        const index = chapters.findIndex((chapter) => chapter.id === id);
        if (index !== -1) {
          set({activeChapter: chapters[index % 2]});
        }
      },

      setNextChapterActive: (id) => {
        const {chapters} = get();
        const index = chapters.findIndex((chapter) => chapter.id === id);
        if (index !== -1) {
          set({activeChapter: chapters[(index + 1) % 2]});
        }
      },
    }),
    {
      name: 'course-storage',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
