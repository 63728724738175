import {Layout} from 'widgets/layout';
import 'react-toastify/dist/ReactToastify.css';

import styles from './index.module.scss';


export const NotFoundPage = () => {
  return (
    <Layout.Main styled={styles.page_header}>
      <h1 className={`container ${styles.base}`}>Страница находится в разработке...</h1>
    </Layout.Main>
  );
};
