import {AxiosError} from 'axios';
import {useState, useCallback} from 'react';
import {useForm as _useForm} from 'react-hook-form';
import {UseMutationResult} from 'react-query';


export const useForm = <T extends object, >(callback: UseMutationResult) => {
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const {register, handleSubmit} = _useForm<T>();

  const onSubmit = useCallback(
    async (params: T) => {
      try {
        setDisabled((disabled) => !disabled);
        await callback.mutateAsync(params);
        setError(null);
      } catch (e: any) {
        setError((e as AxiosError).message);
      } finally {
        setDisabled((disabled) => !disabled);
      }
    },
    [callback, navigator],
  );

  return {disabled, error, register, handleSubmit: handleSubmit.bind(null, onSubmit)};
};
