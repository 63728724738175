import classnames from 'classnames';
import {useCallback} from 'react';
import {v4 as uuid} from 'uuid';

import {useForm} from 'features/form/hooks/use_form';
import {apiService} from 'shared/api';
import {QuestionType} from 'shared/config/types';
import {useCourseMaterials} from 'shared/lib/store';
import {Error, Form, Input} from 'shared/ui';
import {useAnswerQuestionMutation} from '../../hooks/use_answer_question_mutation';
import styles from '../index.module.scss';


export const CourseMaterialQuestion = () => {
  const question = useCourseMaterials((state) => state.activeMaterial);
  const {disabled, error, register, handleSubmit} =
      useForm<any>(useAnswerQuestionMutation(apiService.answerQuestion));

  if (!question) {
    return <>Loading...</>;
  }

  const isCourseMaterialPassed = useCallback(() => {
    return disabled || question.passed || !question.tryNumber;
  }, [disabled, question]);

  if (question.typeQuestion === QuestionType.OPEN) {
    return (
      <div className={styles.material_question}>
        <Form title="Отправить"
              disabled={isCourseMaterialPassed()}
              styled={classnames(styles.material_question__open, !question.tryNumber && 
                styles.material_question__unpassed)}
              onSubmit={handleSubmit()}>
          <Input id="openAnswer"
                 type="text"
                 label="Введите Ваш ответ"
                 placeholder="Ответ на вопрос"
                 disabled={isCourseMaterialPassed()}
                 styled={classnames(styles.material_question__open_field)}
                 refs={register('openAnswer', {required: true})}/>
        </Form>
        {error && <Error message={'Неправильный ответ'} />}
        <div className={styles.material_question_tries}>
          {question.passed ? 'Пройден' : `Попыток осталось: ${question.tryNumber}`}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.material_question}>
      <Form title="Отправить"
            disabled={isCourseMaterialPassed()}
            styled={classnames(styles.material_question__open, !question.tryNumber && 
                styles.material_question__unpassed)}
            onSubmit={handleSubmit()}>
        <div className={styles.material_question__group}>
          {question.variants.map((variant: any, index: number) => (
            <Input id={variant.id}
                   type="radio"
                   key={uuid()}
                   name="singleAnswer"
                   label={variant.content}
                   value={String(index + 1)}
                   disabled={isCourseMaterialPassed()}
                   styled={styles.material_question__single}
                   // eslint-disable-next-line
                   refs={register('singleAnswer', {required: true})}>{variant.content}</Input>))}
        </div>
      </Form>
      {error && <Error message={'Неправильный ответ'}/>}
      <div className={styles.material_question_tries}>
        {question.passed ? 'Пройден' : `Попыток осталось: ${question.tryNumber}`}
      </div>
    </div>
  );
};
