export * from './course_material_route';
export * from './course_route';
export * from './home_route';
export * from './login_route';
export * from './not_found_route';
export * from './private_route';
export * from './registration_route';
export * from './guild_route';

export const routeMap = {
  guild: '/guild',
  home: '/',
  login: '/auth',
  register: '/register',
};
