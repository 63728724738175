import classnames from 'classnames';
import {FC, ReactElement} from 'react';
import {Link} from 'react-router-dom';
import styles from '../index.module.scss';
import {NavItem, NavItemRoute} from '../types';


type HeaderNavigationItemProps = {
  name: NavItem;
  url: NavItemRoute;
  icon: ReactElement;
  active: boolean;
};

export const HeaderNavigationItem: FC<HeaderNavigationItemProps> = ({
  name,
  url,
  icon,
  active,
}) => {
  return (
    <Link to={url.slice(1)}
          className={classnames(styles.nav_item, active && styles.nav_item__active)}>
      {icon}
      <span>{name}</span>
    </Link>
  );
};
