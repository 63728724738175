import {useRoutes} from 'react-router-dom';
import {CourseRoute, GuildRoute, HomeRoute, LoginRoute, NotFoundRoute, PrivateRoute, RegistrationRoute} from './__routing';


export const RootRouter = () => {
  const routes = [
    {
      path: '/',
      name: 'Private Routes',
      element: <PrivateRoute />,
      children: [HomeRoute, GuildRoute, CourseRoute, NotFoundRoute],
    },
    LoginRoute,
    RegistrationRoute,
  ];

  return useRoutes(routes);
};
