import {FC, ReactNode} from 'react';

import {useGuild} from 'shared/lib/store/slices/guild_slice';
import {Template} from 'shared/ui';
import styles from '../index.module.scss';


type GuildInfoProps = {
  children: ReactNode
}

export const GuildInfo: FC<GuildInfoProps> = ({children}) => {
  const guild = useGuild((state) => state.userGuild);
  const progress = (guild?.guildScore || 0) % 10;

  return (
    <Template.FlexWrapper styled={styles.info_guild}>
      <div className={styles.guild_level}>
        <div className={styles.guild_level__topbar}>
          <span className={styles.guild_level__item}>
            Ур. {guild?.lvl}
          </span>
          <span className={styles.guild_level__progress}>
            {progress} / 10
          </span>
        </div>
        <div className={styles.guild_level__progress_bar}
             // @ts-ignore  
             style={{'--progress': `${progress / 10 * 100}%`}}/>
      </div>
      <div className={styles.divider}/>
      <div className={styles.guild_rating}>
        <span>Общий счет</span>
        <span>{guild?.guildScore}</span>
      </div>
      <div className={styles.divider}/>
      <span className={styles.guild_message__heading}>
        Объявление лидера гильдии
      </span>
      {children}
    </Template.FlexWrapper>
  );
};
