import {useGetCourseMaterials} from 'entities/course/course_material';

import {useCourseWeek} from 'shared/lib/store';
import {CourseMaterial} from 'widgets/course/course_material';
import {Layout} from 'widgets/layout';
import styles from './index.module.scss';


export const CourseMaterialPage = () => {
  const activeChild = useCourseWeek((state) => state.activeChild!);
  useGetCourseMaterials('1', activeChild?.id);

  return (
    <Layout.Main styled={styles.page_header}>
      <div className={`container ${styles.base}`}>
        <CourseMaterial/>
      </div>
    </Layout.Main>
  );
};
