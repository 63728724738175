export enum UserRole {
  ROLE_USER = 'ROLE_USER',
  ROLE_LEADER = 'ROLE_GUILD_LEADER',
  ROLE_ADMIN = 'ROLE_ADMIN',
}

export type ErrorResponse = {
  type: 'error';
  message: string;
};

export type PassedMaterialResponse = {
  passed: boolean;
  tryNumber: number;
  experience: number;
  progress: number;
};

export type CommonStyledProps = {
  styled?: string | '';
};

export type CourseCard = {
  id: string;
  name: string;
  description: string;
  done: boolean;
  startDate: string;
  endDate: string;
};

export type GetCourseParams = {
  id: string;
};

export type PostAnswerParams = {
  questionId: string;
  variantIds: string[];
  openAnswer: string;
};

export type PostInfo = {
  info: string;
};

export type SignInParams = {
  login: string;
  password: string;
};

export type SignUpParams = {
  login: string;
  email: string;
  password: string;
};

export type SignInResponse = {
  id: number;
  accessToken: string;
  email: string;
  login: string;
  roles: UserRole[];
  tokenType: 'Bearer';
};

export enum QuestionType {
  OPEN = 'OPEN',
  SINGLE = 'SINGLE',
}

export type Question = {
  id: string;
  chapter: number;
  orderNumber: number;
  type: QuestionType;
  tryNumber: number;
  content: string;
  variants: Array<any>;
};

export enum ChildChapterType {
  QUESTION = 'QUESTION',
  INFO = 'INFO',
}

export type ChapterChild = {
  id: string;
  content: string;
  passed: boolean;
  type: ChildChapterType;
  typeQuestion: any;
  variants: any;
  tryNumber: number;
  videoLink: string;
};

export type Info = {
  id: string;
  chapter: number;
  content: string;
  orderNumber: number;
};

export type Chapter = {
  id: string;
  title: string;
  orderNumber: number;
  children: ChapterChild[];
  questions: Question[];
  infos: Info[];
};

export type Week = {
  id: string;
  title: string;
  orderNumber: number;
  children: Chapter[];
  questions: Question[];
  infos: Info[];
};

export type Course = {
  id: string;
  name: string;
  chapters: Week[] | [];
  description: string;
  progress: number;
  experience: number;
  endDate: Date;
  startDate: Date;
};

export type GuildMember = {
  id: number;
  login: string;
  email: string;
  experience: number;
  lvl: string;
  roles: UserRole[];
  lastOnline: number;
  location: {
    id: number;
    name: string;
  };
};

export type Guild = {
  id: number;
  tagName: string;
  leader: GuildMember;
  guildScore: number;
  lvl: string;
  users: GuildMember[];
  description: string;
};

export type GuildMessage = {
  message: string;
  author: string;
};

export type ChangeGuildMessage = {
  description: string;
};
