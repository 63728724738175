import {CourseMaterialPage} from 'pages/course_material';
import {lazyRoute} from 'shared/lib/lazy_load';


const Course = {
  path: ':courseId',
  name: 'courseId',
  element: lazyRoute('course'),
};

const CourseMaterial = {
  path: ':courseId',
  name: 'courseId',
  children: [
    {
      path: 'material',
      name: 'Material',
      children: [
        {
          path: ':materialId',
          name: 'materialId',
          element: <CourseMaterialPage />,
        },
      ],
    },
  ],
};

export const CourseRoute = {
  path: 'course',
  name: 'Course',
  children: [Course, CourseMaterial],
};
