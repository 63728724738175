// eslint-disable-next-line
// @ts-ignore
import {Scene} from 'castle-mania-ui';
import {useNavigate} from 'react-router-dom';

import {useGetCourse} from 'entities/course/course_material';
import {Button, Spinner} from 'shared/ui';
import {Layout} from 'widgets/layout';
import styles from './index.module.scss';


export const HomePage = () => {
  const {data, isLoading, isFetching} = useGetCourse('1');
  const navigate = useNavigate();

  if (!data || isLoading || isFetching) {
    return <Spinner.Page/>;
  }

  const handleClick = () => {
    navigate('/course/1');
  };

  return (
    <Layout.Main noFooter>
      <Scene />
      <div className={styles.wrapper}>
        <div className={styles.wrapper_inner}>   
          <div className={styles.wrapper_content}>
            <h1>Курс общей информатики</h1>
            <span>
              Курс является вводным для студентов, обучающихся 
              по специальностям, связанным с информационными технологиями. 
              Материалы данного курса содержат всю необходимую информацию 
              о базовых принципах устройства и работы ЭВМ. 
              Большое внимание уделено актуальным технологиям 
              в информационной и компьютерной сфере.
            </span>
            <Button squared
                    onClick={handleClick}>
              Приступить
            </Button>
          </div> 
        </div>
      </div>
    </Layout.Main>
  );
};
