import {useMutation} from 'react-query';
import {useNavigate} from 'react-router-dom';

import {apiService} from 'shared/api';
import {Guild} from 'shared/config/types';
import {sessionService} from 'shared/lib/session_service';
import {useGuild} from 'shared/lib/store/slices/guild_slice';


export const useSubscribeGuildMutation = () => {
  const updateUserGuild = useGuild((state) => state.updateUserGuild);
  const navigate = useNavigate();

  return useMutation(apiService.subscribeGuild, {
    onSuccess(data: Guild) {
      updateUserGuild(data);
    },
    onError() {
      sessionService.revokeSession();
      navigate('/login');
    },
  });
};
