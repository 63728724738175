import {FC, ReactNode} from 'react';
import {FlexCentererd, FlexContainer, FlexWrapper} from './variations';


type TemplateExtensions = {
  FlexCentered: typeof FlexCentererd;
  FlexContainer: typeof FlexContainer;
  FlexWrapper: typeof FlexWrapper;
};

type TemplateProps = {
  children: ReactNode;
};

export const Template: FC<TemplateProps> & TemplateExtensions = ({children}) => {
  return <>{children}</>;
};

Template.FlexCentered = FlexCentererd;
Template.FlexContainer = FlexContainer;
Template.FlexWrapper = FlexWrapper;
