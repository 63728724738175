import classnames from 'classnames';

import {sessionService} from 'shared/lib/session_service';
import {useGuild} from 'shared/lib/store/slices/guild_slice';
import styles from '../index.module.scss';


export const GuildWarBanner = () => {
  const {guilds, userGuild} = useGuild();
  const otherGuild = guilds.find(guild => guild.id !== userGuild?.id);

  if (!userGuild) {
    return <div>Loading...</div>;
  }

  if (userGuild.leader?.login === sessionService.getSessionUser()) {
    sessionService.grantGuildLeaderRole();
  } else if (sessionService.validateGuildLeaderSession()) {
    sessionService.revokeGuildLeaderRole();
  }

  return (
    <div className={styles.guild_war}>
      <div className={styles.guild_war__guild}>
        <div className={classnames(styles.guild_war__guild_logo, 
          (userGuild)!.guildScore > otherGuild!.guildScore && 
            styles.guild_war__winner)}/>
        <div className={styles.guild_war__guild_info}>
          <span className={styles.guild_war__guild_name}>
            {userGuild!.tagName}
          </span>
          <span className={styles.guild_war__guild_score}>
            Общий счет<br/>{userGuild!.guildScore}
          </span>
        </div>
      </div>
      <div className={styles.guild_war__divider}/>
      <div className={styles.guild_war__guild}>
        <div className={classnames(styles.guild_war__guild_logo,
          otherGuild!.guildScore > userGuild!.guildScore && styles.guild_war__winner)}/>
        <div className={styles.guild_war__guild_info}>
          <span className={styles.guild_war__guild_name}>
            {otherGuild!.tagName}
          </span>
          <span className={styles.guild_war__guild_score}>
            Общий счет<br/>{otherGuild!.guildScore}
          </span>
        </div>
      </div>
    </div>
  );
};
