import {FC, ReactNode} from 'react';

import {CommonStyledProps} from 'shared/config/types';
import {Heading, Template} from 'shared/ui';
import styles from './index.module.scss';


type SectionLayoutProps = CommonStyledProps & {
  children: ReactNode;
  title: string;
};

export const SectionLayout: FC<SectionLayoutProps> = ({title, styled, children}) => {
  return (
    <section className={styled}>
      <Template.FlexContainer styled={styles.base}>
        <Heading.Section heading={title} />
        {children}
      </Template.FlexContainer>
    </section>
  );
};
