import {FC, ReactNode} from 'react';
import {SectionContent, SectionTitle} from './specs';


type SectionExtensions = {
  Title: typeof SectionTitle;
  Content: typeof SectionContent;
};

type SectionProps = {
  children: ReactNode;
};

export const Section: FC<SectionProps> & SectionExtensions = ({
  children,
}): JSX.Element => {
  return <>{children}</>;
};

Section.Title = SectionTitle;
Section.Content = SectionContent;
