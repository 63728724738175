import {useMutation} from 'react-query';
import {useCourseMaterials} from 'shared/lib/store';


type AsyncQuery = (params: any) => Promise<any>;

export const useAnswerQuestionMutation = (query: AsyncQuery) => {
  const {
    activeMaterial, 
    setActiveMaterialPassed, 
    setActiveMaterialUnpassed
  } = useCourseMaterials();

  return useMutation(
    async (params: any) => {
      const response = await query({
        questionId: activeMaterial?.id, 
        variantIds: [params.singleAnswer], 
        openAnswer: params.openAnswer, 
      });
      if (!response.passed) {
        throw new Error('Неверный ответ');
      }
    }, {
      onSuccess() {
        if (activeMaterial) {
          setActiveMaterialPassed(activeMaterial.id);
        }
      },
      onError() {
        if (activeMaterial) {
          setActiveMaterialUnpassed(activeMaterial.id);
        }
      },
    });
};
