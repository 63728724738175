import {createElement} from 'react';
import {CoursesIcon, HomeIcon, ScoreIcon} from 'shared/assets/icons';
import {NavItem, NavItemRoute} from '../types';


export const navItemIconMap = {
  [NavItemRoute.MAIN]: createElement(HomeIcon),
  [NavItemRoute.COURSE]: createElement(CoursesIcon),
  [NavItemRoute.GUILD]: createElement(ScoreIcon),
};

export const navItemNameMap = {
  [NavItemRoute.MAIN]: NavItem.MAIN,
  [NavItemRoute.COURSE]: NavItem.COURSE,
  [NavItemRoute.GUILD]: NavItem.GUILD,
};
  
export const navItems: NavItemRoute[] = Object.values(NavItemRoute);

export const getNavItemProps = (url: NavItemRoute) => {
  return {
    name: navItemNameMap[url],
    url,
    icon: navItemIconMap[url],
    active: url === window.location.hash,
  };
};
