export const pluralize = (n: number, titles: string[]): string => {
  let titleIndex: number;

  if (n % 10 === 1 && n % 100 !== 11) {
    titleIndex = 0;
  } else if (
    (n % 10 >= 2 && n % 10 <= 4) &&
    !(n % 100 >= 11 && n % 100 <= 14)
  ) {
    titleIndex = 1;
  } else {
    titleIndex = 2;
  }

  return `${n} ${titles[titleIndex]}`;
};
