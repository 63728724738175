import {FC} from 'react';
import {Template} from 'shared/ui/templates';
import {Heading} from '../../heading';
import styles from '../index.module.scss';
import {HelmetCommonTypes} from '../types';


export const BasicHelmet: FC<HelmetCommonTypes> = ({heading}) => {
  return (
    <Template.FlexWrapper styled={styles.base}>
      <Heading.Secondary heading={heading} />
    </Template.FlexWrapper>
  );
};
