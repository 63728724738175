import classnames from 'classnames';
import {v4 as uuid} from 'uuid';

import {ChapterChild} from 'shared/config/types';
import {useCourseMaterials} from 'shared/lib/store';
import styles from '../index.module.scss';


export const CourseMaterialProgress = () => {
  const {activeMaterial, materials, setActiveMaterial} = useCourseMaterials();

  return (
    <ul className={styles.progress}>
      {materials?.map((material: ChapterChild) => (
        <li key={uuid()}>
          <button type="button"
                  className={classnames(
                    styles.progress_item,
                    material.passed && styles.progress_item__passed,
                    !material.passed && material.tryNumber === 0 &&
                      styles.progress_item__disabled,
                    material.id === activeMaterial?.id &&
                      material.type === activeMaterial?.type &&
                      styles.progress_item__selected)}
                  onClick={() => setActiveMaterial(material)}/>
        </li>
      ))}
    </ul>
  );
};
