import {useQuery} from 'react-query';
import {useNavigate} from 'react-router-dom';
import {apiService} from 'shared/api';
import {ChapterChild, Course} from 'shared/config/types';
import {sessionService} from 'shared/lib/session_service';
import {useCourse, useCourseMaterials, useCourseWeek} from 'shared/lib/store';


export const useGetCourse = (id: string) => {
  const setCourse = useCourse((state) => state.setCourse);
  const setWeek = useCourseWeek((state) => state.setWeek);
  const navigate = useNavigate();

  return useQuery<Course>({
    queryKey: 'course',
    queryFn: () => apiService.getCourse(id),
    onSuccess(course: Course) {
      setCourse(course);
      setWeek(course.chapters[0]);
    },
    onError() {
      sessionService.revokeSession();
      navigate('#/login');
    }
  });
};


export const useGetCourseMaterials = (id: string, chapter: string) => {
  const {setMaterials} = useCourseMaterials();
  const navigate = useNavigate();

  return useQuery<ChapterChild[]>({
    queryKey: ['course-chapter', id, chapter],
    queryFn: () => apiService.getCourseChapter(id, chapter),
    onSuccess(materials: ChapterChild[]) {
      setMaterials(materials);
    },
    onError() {
      sessionService.revokeSession();
      navigate('#/login');
    }
  });
};
