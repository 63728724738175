/* eslint-disable import/no-extraneous-dependencies */
import classnames from 'classnames';
import {ChangeEvent, ComponentPropsWithRef, FC, useState} from 'react';
import {UseFormRegisterReturn} from 'react-hook-form';

import {CommonStyledProps} from 'shared/config/types';
import styles from './index.module.scss';


interface InputProps extends ComponentPropsWithRef<'input'> {
  id: string;
  disabled?: boolean;
  label: string;
  name?: string;
  placeholder?: string;
  refs?: UseFormRegisterReturn<any>;
  type: string;
  value?: string;
}

export const Input: FC<InputProps & CommonStyledProps> = ({
  id,
  disabled,
  label,
  name,
  placeholder,
  refs,
  styled,
  type,
  value,
}) => {
  const [contents, setContents] = useState(value);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target) {
      setContents(e.target.value);
    }
  };

  return (
    <div className={classnames(styles.base, styled && styled)}>
      <input id={id}
             disabled={disabled}
             name={name}
             type={type}
             value={contents}
             placeholder={placeholder}
             {...refs}
             onChange={handleChange}/>
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

Input.defaultProps = {
  disabled: false,
  refs: undefined,
  name: '',
  placeholder: '',
  value: '',
};
