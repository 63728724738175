import {Template} from 'shared/ui/templates';
import styles from '../index.module.scss';


const PageSpinner = () => {
  return (
    <Template.FlexWrapper styled={styles.page}>
      <span>Подождите, идет загрузка...</span>
    </Template.FlexWrapper>);
};

export {PageSpinner};
