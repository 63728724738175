import {UICourseMaterial} from 'entities/course/course_material';
import {ChildChapterType} from 'shared/config/types';
import {useCourseMaterials} from 'shared/lib/store';


export const CourseMaterial = () => {
  const activeMaterial = useCourseMaterials((state) => state.activeMaterial);

  if (activeMaterial?.type === ChildChapterType.INFO) {
    return (
      <>
        <UICourseMaterial>
          <UICourseMaterial.Info />
        </UICourseMaterial>
      </>
    );
  }

  if (activeMaterial?.type === ChildChapterType.QUESTION) {
    return (
      <>
        <UICourseMaterial>
          <UICourseMaterial.Question />
        </UICourseMaterial>
      </>
    );
  }

  return <UICourseMaterial>Загрузка...</UICourseMaterial>;
};
