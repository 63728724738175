import {v4 as uuid} from 'uuid';

import {sessionService} from 'shared/lib/session_service';
import {getNavItemProps, navItems} from '../common';
import styles from '../index.module.scss';
import {HeaderNavigationItem} from '.';


export const HeaderNavigation = () => {
  return (
    <nav>
      <ul className={styles.nav}>
        {navItems.map((item) => {
          if (item.includes('guild') && Boolean(!sessionService.getSessionGuild())) {
            return null;
          }
          return (
            <li key={uuid()}>
              <HeaderNavigationItem {...getNavItemProps(item)} />
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
