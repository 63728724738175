import {FC, ReactNode} from 'react';
import {Heading} from 'shared/ui/heading';
import {HelmetCommonTypes} from '../types';


type ExtendedHelmetProps = HelmetCommonTypes & {
  link: ReactNode;
};

export const ExtendedHelmet: FC<ExtendedHelmetProps> = ({heading, link}) => {
  return (
    <div>
      <Heading.Section heading={heading} />
      <a href="/">{link}</a>
    </div>
  );
};
