import {FC} from 'react';

import styles from './index.module.scss';


type ErrorProps = {
  message: string;
};

export const Error: FC<ErrorProps> = ({message}) => {
  return <span className={styles.base}>{message}</span>;
};
