export enum NavItem {
  MAIN = 'Главная',
  COURSE = 'Карта курса',
  GUILD = 'Гильдия',
}

export enum NavItemRoute {
  MAIN = '#/',
  COURSE = '#/course/1',
  GUILD = '#/guild',
}
