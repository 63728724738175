import {FC, ReactNode} from 'react';
import {PageSpinner} from './variations';


type SpinnerExtensions = {
  Page: typeof PageSpinner;
};

type SpinnerProps = {
  children: ReactNode;
};

export const Spinner: FC<SpinnerProps> & SpinnerExtensions = ({children}) => {
  return <>{children}</>;
};

Spinner.Page = PageSpinner;
