import classnames from 'classnames';
import {FC} from 'react';

import {CommonStyledProps} from 'shared/config/types';
import styles from '../index.module.scss';


type AllowedBadgeProps = CommonStyledProps & {
  info?: string;
  withEffect?: boolean;
};

export const AllowedBadge: FC<AllowedBadgeProps> = ({info, withEffect, styled}) => {
  return (
    <span
      className={classnames(
        styles.base,
        styles.allowed,
        withEffect && styles.effect,
        Boolean(styled) && styled,
      )}
      >
      <span>{info}</span>
    </span>
  );
};

AllowedBadge.defaultProps = {
  withEffect: false,
  info: '0',
};
