import {v4 as uuid} from 'uuid';

import {useCourseWeek} from 'shared/lib/store';
import styles from '../index.module.scss';


export const CourseMaterialMenu = () => {
  const {activeChild, children, setActiveChild} = useCourseWeek();

  return (
    <ul className={styles.aside}>
      {children.map((chapter: any) => (
        <li key={uuid()} className={styles.aside_item}>
          <input id={chapter.title}
                 type="radio"
                 name="contents"
                 checked={activeChild?.id === chapter.id}
                 onChange={() => setActiveChild(chapter.id)}/>
          <label htmlFor={chapter.title}>{chapter.title}</label>
        </li>
      ))}
    </ul>
  );
};
