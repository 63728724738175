import {FC} from 'react';


type SectionTitleProps = {
  title: string;
};

export const SectionTitle: FC<SectionTitleProps> = () => {
  return <>Title</>;
};
