import classnames from 'classnames';
import {FC, ReactNode} from 'react';

import {CommonStyledProps} from 'shared/config/types';
import styles from './index.module.scss';
import {AllowedBadge, ForbiddenBadge} from './specs';


type BadgeExtensions = {
  Allowed: typeof AllowedBadge;
  Forbidden: typeof ForbiddenBadge;
};

type BadgeProps = CommonStyledProps & {
  children: ReactNode;
  title?: ReactNode;
  notification?: ReactNode;
};

export const Badge: FC<BadgeProps> & BadgeExtensions = ({
  children,
  title,
  notification,
  styled,
}) => {
  return (
    <div className={classnames(styles.wrapper, Boolean(styled) && styled)}>
      {title && <span className={styles.title}>{title}</span>}
      {children}
      {notification && <span className={styles.notification}>{notification}</span>}
    </div>
  );
};

Badge.defaultProps = {
  title: '',
  notification: '',
};

Badge.Allowed = AllowedBadge;
Badge.Forbidden = ForbiddenBadge;
