
import {apiService} from 'shared/api';
import {useCourseMaterials} from 'shared/lib/store';
import styles from '../index.module.scss';


export const CourseMaterialInfo = () => {
  const {activeMaterial, setActiveMaterialPassed} = useCourseMaterials((state) => state);

  const handleIframeLoad = async () => {
    if (activeMaterial) {
      await apiService.passInfo({info: activeMaterial.id});
      setActiveMaterialPassed(activeMaterial.id);
    }
  };

  return (
    <div className={styles.material_video}>
      <div className={styles.material_video_wrapper}>
        <iframe src={activeMaterial?.videoLink.replace('/video/private', '/play/embed')}
                allowFullScreen
                allow="autoplay"
                onLoad={handleIframeLoad}/>
      </div>
    </div>
  );
};
