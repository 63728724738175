import {useMutation} from 'react-query';
import {useNavigate} from 'react-router-dom';


type AsyncQuery = (params: any) => Promise<any>;

export const useRedirectMutation = (query: AsyncQuery, url: string) => {
  const navigate = useNavigate();
  return useMutation(query, {
    onSuccess() {
      navigate(url);
    },
  });
};
