import {ReactNode, forwardRef, useEffect, useImperativeHandle, useRef} from 'react';

import closeIcon from '../../assets/icons/icon_close.svg';
import {Button} from '../button';
import styles from './index.module.scss';


type DialogProps = {
  onLoad: boolean;
  onCancel: () => void;
  children: ReactNode;
};

export type DialogMethods = {
  open: () => void;
  close: () => void;
};

export const Dialog = forwardRef<DialogMethods, DialogProps>(
  ({onLoad, onCancel, children}, ref) => {
    const dialog = useRef<HTMLDialogElement>(null);

    useImperativeHandle(ref, () => ({
      open: () => {
        if (dialog.current) {
          dialog.current.showModal();
        }
      },
      close: () => {
        if (dialog.current) {
          dialog.current.close();
        }
      },
    }));

    useEffect(() => {
      if (onLoad && dialog.current && !dialog.current.open) {
        dialog.current.showModal();
      }
    }, []);

    const handleCancel = () => {
      onCancel();
      dialog.current?.close();
    };

    return (
      <dialog className={styles.base} 
              ref={dialog}>
        <div className={styles.container}>
          {children}
          <Button styled={styles.close}
                  type="button"
                  onClick={handleCancel}>
            <img className={styles.close_icon} 
                 src={closeIcon} />
          </Button>
        </div>
      </dialog>
    );
  }
);
