import {LoginForm} from 'features/form'; 
import {Template} from 'shared/ui';

import styles from './index.module.scss';


export const LoginPage = () => {
  return (
    <Template.FlexCentered styled={styles.page}>
      <LoginForm />
    </Template.FlexCentered>
  );
};
