import {FC, ReactNode} from 'react';

import styles from './index.module.scss';


type TooltipProps = {
  message: ReactNode;
}

export const Tooltip: FC<TooltipProps> = ({message}) => {
  return (
    <div className={styles.base}>
      <span className={styles.tooltip}>?</span>
      <span className={styles.tooltip_message}>{message}</span>
    </div>
  );
};
