import {create} from 'zustand';
import {persist, createJSONStorage} from 'zustand/middleware';
import {ChapterChild} from 'shared/config/types';


type CourseMaterials = {
  activeMaterial: ChapterChild | null;
  materials: ChapterChild[];
  setMaterials: (materials: ChapterChild[]) => void;
  setNextMaterial: () => boolean;
  setActiveMaterial: (material: ChapterChild) => void;
  setActiveMaterialPassed: (material: string) => void;
  setActiveMaterialUnpassed: (material: string) => void;
};

export const useCourseMaterials = create<CourseMaterials>()(
  persist(
    (set, get) => ({
      activeMaterial: null,
      materials: [],

      setMaterials: (materials) => {
        const {activeMaterial} = get();
        if (!materials.find((material) => 
              material.id === activeMaterial?.id && 
              material.type === activeMaterial.type)) {
          set({activeMaterial: materials[0], materials});
        }
      },

      setNextMaterial: (): boolean => {
        const {activeMaterial, materials} = get();
        const index = materials.findIndex(
            (material) => (
                material.id === activeMaterial?.id &&
                material.type === activeMaterial.type));
        if (index === materials.length - 1) {
          return true;
        } else if (index !== -1) {
          set({activeMaterial: materials[index + 1]});
        }
        return false;
      },

      setActiveMaterial: (material) => {
        set({activeMaterial: material});
      },

      setActiveMaterialPassed: () => {
        const {activeMaterial, materials} = get();
        if (activeMaterial) {
          activeMaterial.passed = true;
          set({activeMaterial, materials: materials.map(
              (material) => (
                  material.id === activeMaterial.id &&
                  material.type === activeMaterial.type 
                    ? activeMaterial 
                    : material))});
        }
      },

      setActiveMaterialUnpassed: () => {
        const {activeMaterial, materials} = get();
        if (activeMaterial) {
          activeMaterial.tryNumber -= 1;
          set({activeMaterial, materials: materials.map(
              (material) => (
                  material.id === activeMaterial.id &&
                  material.type === activeMaterial.type 
                    ? activeMaterial 
                    : material))});
        }
      },
    }),
    {
      name: 'course-materials-storage',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
