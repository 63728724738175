import {create} from 'zustand';
import {persist, createJSONStorage} from 'zustand/middleware';
import {Chapter, Week} from 'shared/config/types';


type CourseWeekSlice = {
  id: string;
  title: string;
  children: Chapter[];
  activeChild: Chapter | null;
  setWeek: (week: Week) => void;
  setActiveChild: (id: string) => void;
  setNextChildActive: (id: string) => void;
};

export const useCourseWeek = create<CourseWeekSlice>()(
  persist(
    (set, get) => ({
      id: '',
      title: '',
      children: [],
      activeChild: null,

      setWeek: (week) => {
        set({...week, activeChild: week.children[0]});
      },

      setActiveChild: (id) => {
        const {children} = get();
        const index = children.findIndex((child) => child.id === id);
        if (index !== -1) {
          set({activeChild: children[index]});
        }
      },

      setNextChildActive: (id) => {
        const {children} = get();
        const index = children.findIndex((child) => child.id === id);
        if (index !== -1) {
          set({activeChild: children[(index + 1) % children.length]});
        }
      },
    }),
    {
      name: 'course-week-storage',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
