import {FC} from 'react';
import styles from '../index.module.scss';
import {TemplateCommonTypes} from '../types';


export const FlexContainer: FC<TemplateCommonTypes> = ({children, styled = ''}) => {
  return (
    <section className={`container ${styles.container} ${styled}`}>{children}</section>
  );
};
