import classnames from 'classnames';
import {FC, ReactNode} from 'react';

import {CommonStyledProps} from 'shared/config/types';
import {Template} from 'shared/ui';
import styles from './index.module.scss';
import {HeaderNavigation} from './specs';


type HeaderProps = CommonStyledProps & {
  children?: ReactNode;
};

export const Header: FC<HeaderProps> = ({styled, children}) => {
  return (
    <header className={classnames(styles.base, styled && styled)}>
      <Template.FlexContainer styled={styles.layout}>
        <HeaderNavigation />
        {children}
      </Template.FlexContainer>
    </header>
  );
};

Header.defaultProps = {
  children: null,
};
