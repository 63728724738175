import {lazy, Suspense} from 'react';
import {Spinner} from 'shared/ui';


export const lazyRoute = (pageName: string) => {
  const LazyElement = lazy(() => import(`../../pages/${pageName}`));

  return (
    <Suspense fallback={<Spinner.Page />}>
      <LazyElement />
    </Suspense>
  );
};
