import {FC, ReactNode} from 'react';
import {useNavigate} from 'react-router-dom';

import {useCourseMaterials, useCourseWeek} from 'shared/lib/store';
import {Button} from 'shared/ui';
import styles from './index.module.scss';
import {CourseMaterialHeader} from './specs/course_material_header';
import {CourseMaterialInfo} from './specs/course_material_info';
import {CourseMaterialMenu} from './specs/course_material_menu';
import {CourseMaterialProgress} from './specs/course_material_progress';
import {CourseMaterialQuestion} from './specs/course_material_question';


type UICourseMaterialExtensions = {
  Info: typeof CourseMaterialInfo;
  Menu: typeof CourseMaterialMenu;
  Header: typeof CourseMaterialHeader;
  Question: typeof CourseMaterialQuestion;
  Progress: typeof CourseMaterialProgress;
};

type UICourseMaterialProps = {children: ReactNode};

export const UICourseMaterial: FC<UICourseMaterialProps> & UICourseMaterialExtensions = ({
  children,
}) => {
  const {children: c, activeChild, setNextChildActive} = useCourseWeek();
  const {materials, activeMaterial} = useCourseMaterials();

  const isLast = materials.findIndex((material) => (
      material.id === activeMaterial?.id && material.type === activeMaterial?.type)
  ) === materials.length - 1 && activeChild?.id === c[c.length - 1].id;

  const navigate = useNavigate();
  const setNextMaterial = useCourseMaterials((state) => state.setNextMaterial);

  const handleSetNext = () => {
    const isFinished = setNextMaterial();
    if (isFinished && activeChild) {
      setNextChildActive(activeChild.id);
    }
  };

  return (
    <>
      <CourseMaterialHeader/>
      <CourseMaterialMenu/>
      <CourseMaterialProgress/>
      <div className={styles.material}>
        <span className={styles.material_type}>
          {activeMaterial?.type === 'INFO' ? 'Теория' : 'Вопрос'}
        </span>
        <span className={styles.material_title}>
          {activeMaterial?.content || 'Подождите, материал загружается...'}
        </span>
        {children}
        {isLast ? (
          <Button type="button" 
                  onClick={() => navigate('/course/1')}>
            На карту
          </Button>
        ) : (
          <Button type="button" 
                  onClick={() => handleSetNext()}>
            Дальше
          </Button>
        )}
      </div>
    </>
  );
};

UICourseMaterial.Info = CourseMaterialInfo;
UICourseMaterial.Menu = CourseMaterialMenu;
UICourseMaterial.Header = CourseMaterialHeader;
UICourseMaterial.Question = CourseMaterialQuestion;
UICourseMaterial.Progress = CourseMaterialProgress;
