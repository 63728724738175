import {useGuild} from 'shared/lib/store/slices/guild_slice';
import {Template} from 'shared/ui';
import styles from '../index.module.scss';


export const GuildBanner = () => {
  const guild = useGuild((state) => state.userGuild);
  
  return (
    <Template.FlexWrapper styled={styles.info_banner}>
      <span className={styles.info_banner__name}>{guild?.tagName}</span>
    </Template.FlexWrapper>
  );
};
