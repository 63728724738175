import {create} from 'zustand';
import {persist, createJSONStorage} from 'zustand/middleware';
import {Guild} from 'shared/config/types';
import {sessionService} from 'shared/lib/session_service';


type GuildSlice = {
  userGuild: Guild | null;
  guilds: Guild[];
  setGuilds: (guilds: Guild[]) => void;
  updateUserGuild: (guild: Guild) => void;
  setUserGuild: (id: number) => void;
};

export const useGuild = create<GuildSlice>()(
  persist(
    (set, get) => ({
      userGuild: null,
      guilds: [],

      setGuilds: (guilds) => {
        const id = sessionService.getSessionGuild();
        if (id) {
          const userGuild = guilds.find((guild) => guild.id === id);
          set({userGuild});
        }
        set({guilds}); 
      },

      updateUserGuild: (userGuild) => {
        set({userGuild});
      },

      setUserGuild: (id: number) => {
        const {guilds} = get(); 
        const userGuild = guilds.find((guild) => guild.id === id);
        set({userGuild});
      },
    }),
    {
      name: 'guild-storage',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
