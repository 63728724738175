import {FC, ReactNode} from 'react';

import {CommonStyledProps} from 'shared/config/types';
import {MemoizedFooter, Header} from 'shared/ui';


type MainLayoutProps = CommonStyledProps & {
  children: ReactNode;
  noFooter?: boolean;
};

export const MainLayout: FC<MainLayoutProps> = ({styled, children, noFooter = false}) => {
  return (
    <>
      <Header styled={styled} />
      <main style={{overflow: 'hidden', height: 'calc(100vh - 60px)'}}>{children}</main>
      {!noFooter && <MemoizedFooter />}
    </>
  );
};

MainLayout.defaultProps = {
  noFooter: false,
};
