import {Navigate, Outlet} from 'react-router-dom';
import {sessionService} from 'shared/lib/session_service';


export const PrivateRoute = () => {
  if (sessionService.validateSession()) {
    return <Outlet />;
  }
  return <Navigate to="/login" />;
};
