import {UserRole} from 'shared/config/types';


const revokeSession = (): void => {
  sessionStorage.removeItem('session');
};

// eslint-disable-next-line max-len
const createSession = (user: string, token: string, roles: UserRole[], guild?: string): void => {
  sessionStorage.setItem('session', JSON.stringify({user, token, roles, guild}));
};

const getSessionToken = (): string | null => {
  const session = sessionStorage.getItem('session');
  if (!session) {
    return null;
  }
  return JSON.parse(session)?.token;
};

const setSessionGuild = (guild: string): void => {
  const session = sessionStorage.getItem('session');
  if (!session) {
    return;
  }
  sessionStorage.setItem('session', JSON.stringify({
    ...JSON.parse(session),
    guild,
  }));
};

const getSessionUser = (): string | null => {
  const session = sessionStorage.getItem('session');
  if (!session) {
    return null;
  }
  return JSON.parse(session).user;
};

const getSessionGuild = (): number | null => {
  const session = sessionStorage.getItem('session');
  if (!session) {
    return null;
  }
  return Number(JSON.parse(session)?.guild);
};

const grantGuildLeaderRole = (): void => {
  const session = sessionStorage.getItem('session');
  if (!session) {
    return;
  }
  sessionStorage.setItem('session', JSON.stringify({
    ...JSON.parse(session),
    roles: [UserRole.ROLE_USER, UserRole.ROLE_LEADER],
  }));
};

const revokeGuildLeaderRole = (): void => {
  const session = sessionStorage.getItem('session');
  if (!session) {
    return;
  }
  sessionStorage.setItem('session', JSON.stringify({
    ...JSON.parse(session),
    roles: [UserRole.ROLE_USER],
  }));
};

const validateGuildLeaderSession = (): boolean => {
  const session = sessionStorage.getItem('session');
  if (!session) {
    throw new Error('Not a guild leader');
  }
  return JSON.parse(session)?.roles.includes(UserRole.ROLE_LEADER);
};

const validateSession = (): boolean => {
  const session = sessionStorage.getItem('session');
  if (!session) {
    return false;
  }
  return JSON.parse(session)?.roles.includes(UserRole.ROLE_USER);
};

export const sessionService = {
  revokeSession,
  createSession,
  getSessionToken,
  setSessionGuild,
  getSessionGuild,
  validateSession,
  validateGuildLeaderSession,
  revokeGuildLeaderRole,
  grantGuildLeaderRole,
  getSessionUser,
};
